<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="objectData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching object data
      </h4>
      <div class="alert-body">
        No object found with this object id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-objects-list'}"
        >
          Object List
        </b-link>
        for other objects.
      </div>
    </b-alert>

    <template v-if="objectData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <object-view-object-info-card
            :object-data="objectData"
            :order-list="orderList"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <object-view-object-plan-card :object-data="objectData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <object-view-object-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <object-view-object-permissions-card :object-data="objectData" />
        </b-col>
      </b-row>

      <order-list />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import OrderList from '@/views/apps/order/order-list/OrderList.vue'
import objectStoreModule from '../objectStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    OrderList,
  },
  setup() {
    const objectData = ref(null)
    const orderList = ref([])

    const OBJECT_APP_STORE_MODULE_NAME = 'app-object'

    // Register module
    if (!store.hasModule(OBJECT_APP_STORE_MODULE_NAME)) store.registerModule(OBJECT_APP_STORE_MODULE_NAME, objectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OBJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(OBJECT_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-object/fetchObject', { id: router.currentRoute.params.id })
      .then(response => { objectData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          objectData.value = undefined
        }
      })

    return {
      objectData,
      orderList,
    }
  },
}
</script>

<style>

</style>
